<!--
 * @Description:用户端-底部我的-反馈建议-我要反馈 myFeedBack
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-24 12:03:16
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="反馈建议"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />

    <van-form @submit="onSubmit"
              class="form">

      <!-- 反馈类型 选择器 -->

      <van-row class="feedbackType">反馈类型</van-row>
      <van-row class="feedbackType-choose">
        <van-field readonly
                   clickable
                   name="picker"
                   :model="form.feedbackType"
                   :value="value"
                   placeholder="请选择"
                   @click="showPicker = true" />
        <!-- v-for="item in optionsPay"
                           :key="item.value"
                           :label="item.name"
                           :value="item.code" -->

        <van-popup v-model="showPicker"
                   position="bottom">
          <van-picker show-toolbar
                      :columns="FeedbackType"
                      @confirm="onConfirm"
                      @cancel="showPicker = false" />
        </van-popup>
      </van-row>
      <!-- 问题描述 -->
      <van-row class="describeTitle">描述</van-row>
      <van-cell-group class="describe">
        <van-field v-model="form.content"
                   rows='5'
                   type="textarea"
                   placeholder="请说明问题描述与建议，我们将为您不断改进" />
      </van-cell-group>

      <!-- 上传图片 -->
      <van-row class="upLoad">
        <van-uploader upload-text='拍照/上传照片'
                      v-model="fileList"
                      max-count="1"
                      :after-read='afterRead'
                      @oversize="onOversize"
                      preview-size='101' />
      </van-row>
      <van-row class="upLoadTip">支持格式：jpg.png.svg.gif， 单个文件不能超过5MB</van-row>
      <van-row class="contactWay">联系方式</van-row>
      <van-row class="contactWay-input">
        <van-cell-group>
          <van-field v-model="form.concat"
                     placeholder="请输入手机号/邮箱/微信" />
        </van-cell-group>
      </van-row>
      <!-- 提交 -->

      <van-button class="button"
                  block
                  type="info"
                  native-type="submit">提交</van-button>

    </van-form>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import userInfo from '@/utils/getUserInfo'
import { Toast, Dialog } from 'vant'
import Compressor from 'compressorjs'
export default {
  // import引入的组件需要注入到对象中才能使用

  components: {},
  data () {
    // 这里存放数据
    return {

      // 上传表单
      form: {
        concat: userInfo().phoneNumber
      },
      // 反馈类型列表
      FeedbackType: [],
      // 反馈类型编码
      FeedbackTypeCode: '',
      // 上传图片
      fileList: [],
      value: '', // 反馈类型

      // 选择弹出框
      showPicker: false

    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getFeedbackType()
    console.log('userInfo', userInfo())
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 获取反馈类型下拉框
    getFeedbackType () {
      const info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '4324HJKH3F316FDG3LK4H1234L12K313'
          }
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        res.resultEntity.forEach(element => {
          this.FeedbackType.push(element.name)
        })
      })
    },

    // 顶部返回
    handleClickTopBack () {
      this.$router.go(-1)
    },

    // 表单提交
    onSubmit () {
      const info = {
        feedbackType: this.FeedbackTypeCode,
        content: this.form.content,
        picUrl: this.form.picUrl,
        concat: this.form.concat
      }
      console.log(info, 'info')
      if (!this.FeedbackTypeCode) {
        Toast('请选择反馈类型')
      } else if (!this.form.content) {
        Toast('请填写描述信息')
      } else if (!this.form.concat) {
        Toast('请输入联系方式')
      } else {
        if (!this.form.picUrl) {
          Dialog.confirm({
            message: '未上传照片，是否继续提交？'
          }).then(() => {
            this.$myFeedBack.addFeedBack(info).then(res => {
              Toast('反馈建议提交成功')
              this.$router.go(-1)
            }).catch(err => {
              console.error('提交失败', err)
              Toast.fail('提交失败')
            })
          }).catch()
        } else {
          Dialog.confirm({
            message: '确认提交'
          }).then(() => {
            this.$myFeedBack.addFeedBack(info).then(res => {
              Toast('反馈建议提交成功')
              this.$router.go(-1)
            }).catch(err => {
              console.error('提交失败', err)
              Toast.fail('提交失败')
            })
          }).catch()
        }
      }
    },
    // 上传 读取完成
    afterRead (file) {
      if (file instanceof Array) {
        file = file[0]
      }
      file.status = 'uploading'
      file.message = '上传中...'
      // 压缩图片
      const self = this
      /* eslint-disable no-new */
      new Compressor(file.file, {
        quality: 0.1,
        success (result) {
          // 将 Blob 对象转换成 File 对象
          result = new window.File([result], result.name, { type: result.type })
          const formData = new FormData()
          formData.append('file', result)
          self.$myFeedBack.upFile(formData).then(res => {
            file.status = 'done'
            self.form.picUrl = res.resultEntity
            console.log(res.resultEntity, '+++++++++')
          }).catch(err => {
            console.log('图片上失败', err)
            file.status = 'failed'
            file.message = '上传失败'
          })
        },
        error (err) {
          file.status = 'failed'
          file.message = '图片压缩失败'
          console.log('压缩失败', err)
        }
      })
    },

    // 限制大小
    onOversize (file) {
      console.log(file)
      Toast('文件大小不能超过 5MB')
    },

    // 选择框确认
    onConfirm (value, index) {
      // console.log(value, '+++++++++')
      this.value = value
      this.showPicker = false
      this.FeedbackTypeCode = index + 1
      console.log(this.FeedbackTypeCode, '+++++++++')
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .form {
    margin: 0 3.2%;
    // height: 100%;
    // width: 93.6%;
    // 反馈类型
    .feedbackType {
      margin-top: 10px;
      height: 23px;
      font-size: 15px;
      color: #333333;
      line-height: 23px;
    }
    // 反馈类型-选择
    .feedbackType-choose {
      height: 40px;
      margin-top: 10px;
    }
    //描述标题
    .describeTitle {
      margin-top: 15px;
      height: 23px;
      font-size: 15px;
      color: #333333;
      line-height: 23px;
    }
    //描述
    .describe {
      margin-top: 10px;
      height: 140px;
    }

    .upLoad {
      margin-top: 25px;
      // background: red;
      height: 135px;
      // border: 1px solid #dddddd;
      // border-radius: 5px;
      /deep/ .van-uploader__upload {
        height: 135px !important;
        border-radius: 5px;
        border: 1px solid #dddddd;
      }
    }
    // 上传提示
    .upLoadTip {
      // width: 463px;
      height: 11px;
      font-size: 10px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 23px;
    }
    // 联系方式
    .contactWay {
      margin-top: 19px;
    }
    .contactWay-input {
      margin-top: 11px;
    }

    .button {
      margin-top: 30px;
      border-radius: 5px;
    }
    /deep/.van-uploader__preview-image {
      height: 135px !important;
      border-radius: 5px;
    }
  }
}
</style>
